<!--
  Placeholder component used when the Banner component is missing for certain brands,
  so that we can have static imports for the Banner component in the DefaultPage component.
-->

<template>
  <div></div>
</template>

<script setup></script>
